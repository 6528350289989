import React, { FunctionComponent } from "react";
import { LatLngLiteral } from "leaflet";
import { useMap } from "react-leaflet";
import { Marker, Popup } from "react-leaflet";
import { Icon } from 'leaflet';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Box, Grid, Modal, Paper, Typography, styled } from "@material-ui/core";
import CustomRating from "./CustmizeAddPetModel";
const dummyImage=require('./dummy.svg');
import { DayCareServices } from "../../blocks/catalogue/src/types";
import { getReviewMessage } from "./CommonFunctions";
export interface Image {
  id: number
  url: string
  name: string
  content_type: string
  size: number
  is_cover_image: boolean
}

export interface HotelDetailsData {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  account_id: number
  name: string
  legal_business_name?: string
  license_number?: string
  vat_number?: string
  hotel_address_line_1?: string
  hotel_address_line_2?: string
  hotel_city?: string
  hotel_country?: string
  hotel_postcode?: string
  hotel_country_region?: string
  latitude: number
  longitude: number
  phone_number_country_code?: string
  phone_number: string
  email: string
  first_name?: string
  surname?: string
  representive_country_code?: string
  reprsentive_phone_number?: string
  address_line_1?: string
  address_line_2?: string
  city_town: string
  country?: string
  postcode: string
  country_region: string
  budget_per_day: number
  pet_type: string
  rating?: number
  description: string
  title?: string
  daycare_price_for_dog: number
  daycare_price_for_cat: number
  property_name: string
  flat_suite: string
  is_active: boolean
  cover_image_id: string
  hotel_additional_services: object[]
  "Create your own services": object[]
  images: Image[]
  documents: object[]
  reviews_count: number
  "Types of rooms": object[]
  "How many rooms are at the properties?": object
  "Pet type": object
  Status: boolean
  blocked_off_days: string[];
  day_care_services: DayCareServices
}
interface Props {
  coords: [number, number],
  markerIconPng: string,
  locationIcon: string,
  classes: ClassNameMap<string>
  hotelData: HotelDetailsData
}

const MapMarker: FunctionComponent<Props> = ({
  coords,
  markerIconPng,
  locationIcon,
  classes ,
  hotelData
}) => {

  const setViewOnClick = (coords: [number, number]) => {
    const [latitude, longitude] = coords;
    const latLng: LatLngLiteral = { lat: latitude, lng: longitude };
    const mapValue = useMap();
    mapValue.setView(latLng, mapValue.getZoom());
    return null;
  };

  const renderModal =  (
      <SaveModal>
        <Paper elevation={0} className="modalPaper">
                <div className="imgDiv">
                    <img style={{ objectFit: "cover" }} src={hotelData.attributes.images[0]?.url || dummyImage } alt="image" className="image"/>
                </div>
                <Box className="contentDiv">
                    <Box className="locationDiv">
                        <img src={locationIcon} alt="image" className="location"/>
                        <Typography className="locationText">
                            {hotelData.attributes.hotel_city},&nbsp;{hotelData.attributes.hotel_country_region}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className="hotelName">
                            {hotelData.attributes.name}
                    </Typography>
                    </Box>        
                </Box>
                <Box className="detialsDiv">
                    <Box className="priceBox">
                        <Typography className="priceText">
                          £{hotelData?.attributes?.budget_per_day || hotelData.attributes?.day_care_services?.data[0]?.attributes?.price_for_one_day || 0}
                        </Typography>
                        <Typography className="priceTypeText">
                            Starting from per day
                        </Typography>
                    </Box>
                    <Box>
                       <Box display={'flex'} alignItems={'center'}>
                       <Typography display="inline" variant="body2" color="textSecondary" className={classes.ratingText}>{hotelData.attributes?.rating}</Typography>
                        <CustomRating rating={hotelData.attributes?.rating} />
                       </Box>
                        <Grid container alignItems="center" justifyContent="space-between" className={classes.ratingReviewLayout}>
                            <Grid item>
                            </Grid>
                            <Grid item>
                                <Typography display="inline" variant="body2" color="textSecondary" className={classes.ratingText}>
                                  {getReviewMessage(hotelData?.attributes?.reviews_count)}
                                  
                                  </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
        </Paper>
      </SaveModal>
  );
  
  return (
    <>
      {setViewOnClick(coords)}
      <Marker 
        position={coords} 
        icon={new Icon({ iconUrl: markerIconPng, iconSize: [40, 40], iconAnchor: [12, 41] })} 
      >
        <CustomPopup>{renderModal}</CustomPopup>
      </Marker>
    </>
  );
};

export default MapMarker;

const CustomPopup = styled(Popup)({
  position: "relative",
  "& .leaflet-popup-content p": {
    margin: "0"
  },
  "& .leaflet-popup-content-wrapper": {
    padding: "0px",
    borderRaidus: "15px"
  },
  "& .leaflet-popup-tip-container":{
    display: "none"
  }
});
const SaveModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  "& .modalPaper": {
      borderRadius: "15px",
      opacity: "0px"
  },
  "& .imgDiv": {
      width: "340px",
      height: "170px",
      gap: "10px",
      opacity: "0px",
  },
  "& .image": {
      width: "100%",
      height: "100%",
      borderRadius: "15px 15px 0px 0px"
  },
  "& .contentDiv":{
      width: "301px",
      margin: "10px auto"
  },
  "& .locationDiv":{
      display: "flex"
  },
  "& .locationText": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "12px",
      textAlign: "left",
      paddingLeft: "5px",
      color: "rgba(71, 85, 105, 1)"
  },
  "& .location": {
      width: "15px",
      height: "15px"
  },
  "& .hotelName":{
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      textAlign: "left",
      color: "rgba(15, 23, 42, 1)",
      overflowWrap: "anywhere",
  },
  "& .detialsDiv":{
      display: "flex",
      justifyContent: "space-between",
      width: "301px",
      margin: "0px auto"
  },
  "& .priceBox": {
      width: "143px"
  },
  "& .priceText": {
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      textAlign: "left",
      color: "rgba(15, 23, 42, 1)"
  },
  "& .priceTypeText": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "left",
      color: "rgba(71, 85, 105, 1)",
      paddingBottom: "5px",
  },
  "& .ratingBox": {
      width: "120px"
  }
});